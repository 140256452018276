// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-edit-customer-tsx": () => import("./../../../src/pages/admin/edit-customer.tsx" /* webpackChunkName: "component---src-pages-admin-edit-customer-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-create-protocol-insp-tsx": () => import("./../../../src/pages/create-protocol/insp.tsx" /* webpackChunkName: "component---src-pages-create-protocol-insp-tsx" */),
  "component---src-pages-create-protocol-kont-tsx": () => import("./../../../src/pages/create-protocol/kont.tsx" /* webpackChunkName: "component---src-pages-create-protocol-kont-tsx" */),
  "component---src-pages-create-protocol-mr-tsx": () => import("./../../../src/pages/create-protocol/MR.tsx" /* webpackChunkName: "component---src-pages-create-protocol-mr-tsx" */),
  "component---src-pages-create-protocol-rr-tsx": () => import("./../../../src/pages/create-protocol/RR.tsx" /* webpackChunkName: "component---src-pages-create-protocol-rr-tsx" */),
  "component---src-pages-customers-add-to-customer-portal-tsx": () => import("./../../../src/pages/customers/add-to-customer-portal.tsx" /* webpackChunkName: "component---src-pages-customers-add-to-customer-portal-tsx" */),
  "component---src-pages-customers-customer-portal-tsx": () => import("./../../../src/pages/customers/customer-portal.tsx" /* webpackChunkName: "component---src-pages-customers-customer-portal-tsx" */),
  "component---src-pages-customers-index-tsx": () => import("./../../../src/pages/customers/index.tsx" /* webpackChunkName: "component---src-pages-customers-index-tsx" */),
  "component---src-pages-customers-single-tsx": () => import("./../../../src/pages/customers/single.tsx" /* webpackChunkName: "component---src-pages-customers-single-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoicing-tsx": () => import("./../../../src/pages/invoicing.tsx" /* webpackChunkName: "component---src-pages-invoicing-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-notifications-tsx": () => import("./../../../src/pages/notifications.tsx" /* webpackChunkName: "component---src-pages-notifications-tsx" */),
  "component---src-pages-offers-tsx": () => import("./../../../src/pages/offers.tsx" /* webpackChunkName: "component---src-pages-offers-tsx" */),
  "component---src-pages-reservoirs-index-tsx": () => import("./../../../src/pages/reservoirs/index.tsx" /* webpackChunkName: "component---src-pages-reservoirs-index-tsx" */),
  "component---src-pages-reservoirs-single-tsx": () => import("./../../../src/pages/reservoirs/single.tsx" /* webpackChunkName: "component---src-pages-reservoirs-single-tsx" */),
  "component---src-pages-servicelogs-tsx": () => import("./../../../src/pages/servicelogs.tsx" /* webpackChunkName: "component---src-pages-servicelogs-tsx" */),
  "component---src-pages-workorders-tsx": () => import("./../../../src/pages/workorders.tsx" /* webpackChunkName: "component---src-pages-workorders-tsx" */),
  "component---src-pages-workplans-tsx": () => import("./../../../src/pages/workplans.tsx" /* webpackChunkName: "component---src-pages-workplans-tsx" */)
}

